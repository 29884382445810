import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule, Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { TranslateModule, TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [TranslateModule, RouterModule, NgIf],
  templateUrl: './breadcrumb.component.html',
  styleUrls: [],
})
export class AppBreadcrumbComponent implements OnInit {
  pageInfo: Data | any = Object.create(null);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService
  ) {}

   //  {
  //   this.router.events
  //     .pipe(filter((event) => event instanceof NavigationEnd))
  //     .pipe(map(() => this.activatedRoute))
  //     .pipe(
  //       map((route) => {
  //         while (route.firstChild) {
  //           route = route.firstChild;
  //         }
  //         return route;
  //       }),
  //     )
  //     .pipe(filter((route) => route.outlet === 'primary'))
  //     .pipe(mergeMap((route) => route.data))
  //     // tslint:disable-next-line - Disables all
  //     .subscribe((event) => {
  //       // tslint:disable-next-line - Disables all
  //       this.translate.get(event['title']).subscribe((translatedTitle: string) => {
  //         this.titleService.setTitle(translatedTitle);
  //       });
  //       this.pageInfo = event;
  //     });
  // }

  ngOnInit() {
    this.listenToRouterEvents();
    this.listenToLangChange();
  }

  private listenToRouterEvents() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => this.updateTitle(event));
      this.updateTitleInfo()
  }

  private listenToLangChange() {
    this.translate.onLangChange.subscribe(() => {
      this.updateTitleInfo();
    });
  }

  private updateTitle(event: Data) {
    this.translate.get(event['title']).subscribe((translatedTitle: string) => {
      this.titleService.setTitle(translatedTitle);
    });
    this.pageInfo = event;
  }

  private updateTitleInfo() {
    let currentRoute = this.activatedRoute;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    currentRoute.data.subscribe(event => this.updateTitle(event));
  }
}

import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from 'src/app.config';
import { BaseService } from './base.service';
import { Cabinet } from './cabinet.service';
import { Permission } from '../models/permission';
import { navItems } from 'src/app/layouts/full/vertical/sidebar/sidebar-data';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  myAppUrl: string;

  constructor(
    private cabinetService: Cabinet,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  showBasicComponent(message: string, snackClass: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: `${message}` },
      duration: 2000,
      panelClass: [snackClass],
      verticalPosition: 'top',
    });
  }

  getPermission() {
    return Permission;
  }

  isMine(username) {
    return this.getUserName() == username;
  }

  hasPermission(permission) {
    if (permission) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(localStorage.getItem('token'));
      if (decodedToken) {
        let permissions = JSON.parse(decodedToken['Permissions']) as Object;
        if (permission == Permission.ViewProfile) return true;
        return permissions.hasOwnProperty(permission);
      }
      return false;
    }
    return false;
  }

  createJWT(token) {
    localStorage.setItem('token', token);
    this.router.navigate([
      navItems.find((x) => this.hasPermission(x.permission)).route,
    ]);
  }

  normalLogin(model): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'auth/login-employee';
    return this.http.post<any>(url, model);
  }
  logOut(): void {
    localStorage.removeItem('token');
    this.router.navigate(['login']);
  }

  forgotBoxedPassword(email: string): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'auth/forgot-password?email=' + email;
    return this.http.post<any>(url, { email });
  }

  // resetPassword(model): Observable<any> {
  //   this.myAppUrl = AppConfig.settings.other.resourceApiURI;
  //   let url = this.myAppUrl + 'auth/reset-password';
  //   return this.http.post<any>(url, model);
  // }

  resetBoxedPassword(model: { password: string; confirmPassword: string, token: string, email: string }): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    const url = `${this.myAppUrl}auth/reset-password`;
    console.log(url);
    return this.http.post<any>(url, model);
  }



  getDecodedAccessToken(token: string): any {
    try {
      //   return jwt_decode(token);
    } catch (Error) {
      console.log(Error);
      return null;
    }
  }

  getUserName() {
    if (localStorage.getItem('token')) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(localStorage.getItem('token'));
      if (decodedToken && decodedToken['UserName']) {
        return decodedToken['UserName'];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }


  getFullName() {
    if (localStorage.getItem('token')) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(localStorage.getItem('token'));
      if (decodedToken && decodedToken['FullName']) {
        return decodedToken['FullName'];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }


  getUserId() {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        return decodedToken['Id'];
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getWeatherForecast(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'WeatherForecast';
    return this.http.get<any>(url);
  }
  isLogin(): boolean {
    const token = this.getToken();
    const helper = new JwtHelperService();
    let isTokenExpired = helper.isTokenExpired(token);
    if (isTokenExpired) this.logOut();
    return !isTokenExpired;
  }
}

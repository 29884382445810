export enum Permission {
    ViewBuilding = "view_building",
    CreateBuilding = "create_building",
    UpdateBuilding = "update_building",
    DeleteBuilding = "delete_building",

    ViewVendor = "view_vendor",
    CreateVendor = "create_vendor",
    UpdateVendor = "update_vendor",
    DeleteVendor = "delete_vendor",

    ViewRequest = "view_request",
    CreateRequest = "create_request",
    UpdateRequest = "update_request",

    ViewBuildingService = "view_building_service",
    CreateBuildingService = "create_building_service",
    UpdateBuildingService = "update_building_service",
    DeleteBuildingService = "delete_building_service",

    ViewServiceProvider = "view_service_provider",
    CreateServiceProvider = "create_service_provider",
    UpdateServiceProvider = "update_service_provider",
    DeleteServiceProvider = "delete_service_provider",

    ViewEmployee = "view_employee",
    CreateEmployee = "create_employee",
    UpdateEmployee = "update_employee",
    DeleteEmployee = "delete_employee",

    ViewAgreement = "view_agreement",
    CreateAgreement = "create_agreement",
    UpdateAgreement = "update_agreement",
    DeleteAgreement = "delete_agreement",

    ViewCustomer = "view_customer",
    CreateCustomer = "create_customer",
    UpdateCustomer = "update_customer",
    DeleteCustomer = "delete_customer",

    ViewPayment = "view_payment",
    CreatePayment = "create_payment",

    ViewProfile = "view_profile",

    ViewInvoice = "view_invoice",
    CreateInvoice = "create_invoice",
    UpdateInvoice = "update_invoice",
    DeleteInvoice = "delete_invoice",

    SendMessage = "send_message",

}
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AccountService } from './account-service';
import { Router } from '@angular/router';


@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private accountService: AccountService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    var token = this.accountService.getToken();
    req = req.clone({
      setHeaders: { Authorization: "bearer " + token }
    });
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          const returnUrl = this.router.url;
          this.accountService.logOut();
          // Token expired or unauthorized, redirect to login
          this.router.navigate(['/login'],{ queryParams: { returnUrl } });
        }
        return throwError(error);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AccountService } from './account-service';
import { navItems } from 'src/app/layouts/full/vertical/sidebar/sidebar-data';

@Injectable()
export class AuthGuardService {

  constructor(private accountService: AccountService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.accountService.isLogin()) {
      let permission = route.data['permission'];
      let hasPermission = this.accountService.hasPermission(permission);
      return hasPermission;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}

import { NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-branding',
  standalone: true,
  imports: [NgIf, NgClass],
  template: `
    <div class="branding" [ngClass]="sidenavCollapsed() ? 'py-3 px-0': ''">
      <a href="/" *ngIf="options.theme === 'light'">
        <img
          [src]="sidenavCollapsed() ? './assets/images/logos/logo.svg': './assets/images/logos/light-logo-v2.svg'"
          class="img-fluid" [ngClass]="sidenavCollapsed() ? 'm-l-10': 'p-l-20 p-r-20'"
          alt="logo"
        />
      </a>
      <a href="/" *ngIf="options.theme === 'dark'">
      <img
          [src]="sidenavCollapsed() ? './assets/images/logos/logo.svg': './assets/images/logos/light-logo-v2.svg'"
          class="img-fluid" [ngClass]="sidenavCollapsed() ? 'm-l-10': 'p-l-20 p-r-20'"
          alt="logo"
        />
      </a>
    </div>
  `,
})
export class BrandingComponent {
  options = this.settings.getOptions();

  constructor(private settings: CoreService) {

  }
  sidenavCollapsed() {
    return this.options.sidenavCollapsed;
  }
}
